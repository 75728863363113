import React, { useEffect, useState } from 'react';
import axios from 'axios';

import ErrorBoundary from './ErrorBoundary';
import ApexChart from './ApexChart';

// const API_URL = 'http://localhost:3300/api';
const API_URL = 'https://bnagvip.lt365vi.com/api';

function App() {
  const [currentDate, setCurrentDate] = useState('');
  const [historyData, setHistoryData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [resultR1, setResultR1] = useState({});
  const [resultR2, setResultR2] = useState({});
  const [flagClose, setFlagClose] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [timeOfResultShow, setTimeOfResultShow] = useState(null);
  const [selectedResult, setSelectedResult] = useState('');
  const [currentTimeHHMM, setCurrentTimeHHMM] = useState('');
  const [rewardResult, setRewardResult] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const [openPrice, setOpenPrice] = useState('');
  const [highestPrice, setHighestPrice] = useState('');
  const [lowestPrice, setLowestPrice] = useState('');

  const getCurrentTimeHHMM = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const compareTimes = (time1, time2) => {
    if (!time1 || !time2) {
      // Handle undefined time strings gracefully
      return 0; // or return an appropriate value
    }

    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    if (hours1 < hours2) return -1;
    if (hours1 > hours2) return 1;
    if (minutes1 < minutes2) return -1;
    if (minutes1 > minutes2) return 1;
    return 0;
  };

  const formatNumberWithPlus = (numberString) => {
    const number = parseFloat(numberString);

    if (!isNaN(number) && number >= 0) {
      return `+${number.toFixed(2)}`;
    } else {
      return numberString;
    }
  };

  const convertFormatDateMonthDayYear = (getDateTime) => {
    // console.log('getDateTime : ', getDateTime);
    const inputDate = new Date(getDateTime);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const formattedDateTime = `${
      months[inputDate.getMonth()]
    } ${inputDate.getDate()}, ${inputDate.getFullYear()} ${inputDate.toLocaleTimeString(
      [],
      { hour: '2-digit', minute: '2-digit' }
    )}`;

    const formattedDate = `${
      months[inputDate.getMonth()]
    } ${inputDate.getDate()}, ${inputDate.getFullYear()}`;

    return formattedDate;
  };

  const fetchLotteryRewardResult = async (getDate) => {
    try {
      if (dataFetched) {
        return;
      }

      const requestBody = {
        lotteryId: '6505f8ad35d1e4e1ae1ae3a9',
        startTime: '09:00',
        endTime: '16:00',
      };

      const response = await axios.post(
        `${API_URL}/lottery-reward-result-nk`,
        requestBody
      );

      // Check if the request was successful
      if (response.status === 200) {
        console.log('response.data : ', response.data);

        let data = response.data.results.results;
        // if (data.r1 && data.r1.price !== null && data.r1.price !== undefined && data.r1.price !== "") {
        if (data.r1?.price) {
          setResultR1(response.data.results.results.r1);
          setFlagClose(false);
        } else {
          setResultR1({});
          setFlagClose(false);
        }

        if (data.r2?.price) {
          setResultR2(response.data.results.results.r2);
          setFlagClose(true);
        } else {
          setResultR2({});
          setFlagClose(false);
        }

        // console.log(response.data.results.stocks);

        setOpenPrice(response.data.openPrice);
        setStockData(response.data.results.stocks);
        setTimeOfResultShow(response.data.timeOfResultShow);
        let currentTimeHHMM = getCurrentTimeHHMM();
        setCurrentTimeHHMM(currentTimeHHMM);
        const selectedResult =
          currentTimeHHMM >= response.data.timeOfResultShow.Morning
            ? 'resultR1'
            : 'resultR2';

        setSelectedResult(selectedResult);

        setCurrentDate(
          convertFormatDateMonthDayYear(response.data.results.dateString)
        );

        // console.log(response.data.lotteryRewardResult);
        setLowestPrice(response.data.lowestPrice);
        setHighestPrice(response.data.highestPrice);
        setRewardResult(response.data.lotteryRewardResult);
        setHistoryData(response.data.historyReward);
        setSeriesData(response.data.seriesData);
      } else {
        console.error(
          'API request failed:',
          response.status,
          response.statusText
        );
        return null;
      }
    } catch (error) {
      console.error(
        'An error occurred while fetching data from the API:',
        error
      );
      return null;
    }
  };

  useEffect(() => {
    const now = new Date();
    const months = [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
    ];

    const year = now.getFullYear();
    const month = months[now.getMonth()];
    const day = String(now.getDate()).padStart(2, '0');

    const sendBodyDate = `${year}-${month}-${day}`;
    fetchLotteryRewardResult(sendBodyDate);

    const interval = setInterval(async () => {
      await fetchLotteryRewardResult(sendBodyDate);
    }, 3 * 60 * 1000); // Fetch data every 3 minutes

    return () => clearInterval(interval);
  }, [dataFetched]);

  const convertFormatDate = (dateString) => {
    const dateObj = new Date(dateString);

    // Define an array of month names
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // Get the month, day, and year components from the date object
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();

    // Create the formatted date string
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const convertFormatDateYYYYMMDD = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and format.
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };

  const convertFormatDateDDMMYYYY = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1 and format.
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const formatNumberWithCommas_v1 = (number) => {
    return number.toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatNumberWithCommas = (number) => {
    // Check if the input is a valid number and not NaN
    if (typeof number === 'number' && !isNaN(number)) {
      return number.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      // If the input is not a valid number or NaN, return "0.00"
      return '0.00';
    }
  };

  const getRandomValueAroundPercentage = (value, percentage) => {
    const minValue = value * percentage;
    const maxValue = -minValue;

    // Generate a random value within the defined range
    const randomValue = Math.random() * (maxValue - minValue) + minValue;

    // Check if the result is NaN or not a finite number
    if (isNaN(randomValue) || !isFinite(randomValue)) {
      console.warn(
        `Random value is not a valid number, returning the original value ${value}`
      );
      return value;
    }

    // Add the random value to the original value and round to two decimal places
    const result = (value + randomValue).toFixed(2);

    if (result > value) {
      console.log(
        `New value ${result} is greater than the original value ${value}`
      );
    } else if (result < value) {
      console.log(
        `New value ${result} is less than the original value ${value}`
      );
    } else {
      console.log(
        `New value ${result} is equal to the original value ${value}`
      );
    }

    return result;
  };

  return (
    <div className='App'>
      <ErrorBoundary>
        <div className='container-fluid px-0'>
          <div className='row mx-0'>
            <div className='col-12 col-xl-8 offset-xl-2 px-0'>
              <div id='cnindex'>
                <div className='header'>
                  <img src='assets/img/cn-header.png' />
                </div>
                <div className='cn-content'>
                  <div
                    className='text-center position-relative'
                    style={{ padding: '40px 0 20px 0', zIndex: 2 }}
                  >
                    <span className='txt-n'> SHENZHEN COMPONENT VI </span>
                  </div>
                  <div
                    className='content-detail position-relative'
                    style={{ zIndex: 2 }}
                  >
                    <div className='row m-0'>
                      <div className='col-xs-12 col-lg-4 col-md-5 col-left'>
                        <div className='p-0 p-md-2'>
                          <div className='index-detail'>
                            <div
                              className={
                                stockData[0]?.priceData[0]?.status === 'up'
                                  ? 'title up'
                                  : 'title down'
                              }
                            >
                              {parseFloat(stockData[0]?.priceData[0]?.price)}
                            </div>
                            <ul>
                              <li>
                                <span className='name'>Date</span>
                                <div className='value'>
                                  <span>{currentDate}</span>
                                </div>
                              </li>
                              <li>
                                <span className='name'>Prev.Close</span>
                                <div className='value'>
                                  <span> {resultR1.price} </span>
                                </div>
                              </li>
                              <li
                                className={
                                  openPrice.priceStatus === 'up'
                                    ? 'value-cont up'
                                    : 'value-cont down'
                                }
                              >
                                <span className='name'>Open</span>
                                <div className='value'>
                                  <span>{openPrice.price}</span>
                                </div>
                              </li>
                              <li
                                className={
                                  parseFloat(openPrice.price) <
                                  parseFloat(stockData[0]?.priceData[0]?.price)
                                    ? 'value-cont up'
                                    : 'value-cont down'
                                }
                              >
                                <span className='name'>Last</span>
                                <div className='value'>
                                  <span>
                                    {' '}
                                    {parseFloat(
                                      stockData[0]?.priceData[0]?.price
                                    )}{' '}
                                  </span>
                                </div>
                              </li>
                              <li
                                className={
                                  parseFloat(openPrice.price) <
                                  parseFloat(stockData[0]?.priceData[0]?.price)
                                    ? 'value-cont up'
                                    : 'value-cont down'
                                }
                              >
                                <span className='name'>Change</span>
                                <div className='value'>
                                  <span>
                                    {' '}
                                    {(
                                      parseFloat(openPrice.price) -
                                      parseFloat(
                                        stockData[0]?.priceData[0]?.price
                                      )
                                    ).toFixed(2)}{' '}
                                  </span>
                                </div>
                              </li>
                              <li
                                className={
                                  parseFloat(openPrice.price) <
                                  parseFloat(stockData[0]?.priceData[0]?.price)
                                    ? 'value-cont up'
                                    : 'value-cont down'
                                }
                              >
                                <span className='name'>Change（%）</span>
                                <div className='value'>
                                  <span>
                                    {' '}
                                    {(
                                      (parseFloat(openPrice.price) -
                                        parseFloat(
                                          stockData[0]?.priceData[0]?.price
                                        )) /
                                      parseFloat(openPrice.price)
                                    ).toFixed(2)}{'%'}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <span className='name'>High</span>
                                <div className='value'>
                                  <span> {parseFloat(highestPrice)} </span>
                                </div>
                              </li>
                              <li>
                                <span className='name'>Low</span>
                                <div className='value'>
                                  <span> {parseFloat(lowestPrice)} </span>
                                </div>
                              </li>
                              <li>
                                <span className='name'>Volume（10Bil.）</span>
                                <div className='value'>
                                  <span>
                                    {' '}
                                    {stockData[0]?.priceData[0]?.volumn}{' '}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <span className='name'>Amount（100Mil.）</span>
                                <div className='value'>
                                  <span>
                                    {' '}
                                    {stockData[0]?.priceData[0]?.Amount}{' '}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='col-xs-12 col-lg-8 col-md-7'>
                        <div
                          className='p-0 p-md-2'
                          style={{ position: 'relative' }}
                        >
                          <div style={{ minHeight: 295 }}>
                            <div className='d-flex'>
                              <div style={{ width: '100%' }}>
                                <ApexChart seriesData={seriesData} width={'100%'} height={200} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='today-result'>
                          <table className='table table-bordered'>
                            <tbody>
                              <tr>
                                <td>ปิดเช้า</td>
                                <td>บน</td>
                                <td
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    color: '#df280f',
                                  }}
                                >
                                  {rewardResult?.Morning?.digits3}
                                </td>
                                <td>ล่าง</td>
                                <td
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    color: '#df280f',
                                  }}
                                >
                                  {rewardResult?.Morning?.digits2}
                                </td>
                              </tr>
                              <tr>
                                <td>ปิดเย็น</td>
                                <td>บน</td>
                                <td
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    color: '#df280f',
                                  }}
                                >
                                  {rewardResult?.Evening?.digits3}
                                </td>
                                <td>ล่าง</td>
                                <td
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    color: '#df280f',
                                  }}
                                >
                                  {rewardResult?.Evening?.digits2}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='history position-relative'
                    style={{ zIndex: 2 }}
                  >
                    <h3>ผลย้อนหลัง</h3>
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          <th rowSpan={2}>วันที่</th>
                          <th colSpan={2}>ปิดเช้า</th>
                          <th colSpan={2}>ปิดเย็น</th>
                        </tr>
                        <tr>
                          <th>บน</th>
                          <th>ล่าง</th>
                          <th>บน</th>
                          <th>ล่าง</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyData?.map((item, idx) => (
                          <tr key={idx}>
                            <td>
                              {convertFormatDateDDMMYYYY(item.dateString)}
                            </td>
                            <td>{item.morningDigits3}</td>
                            <td>{item.morningDigits2}</td>
                            <td>{item.eveningDigits3}</td>
                            <td>{item.eveningDigits2}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='cn-final-footer' />
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default App;
